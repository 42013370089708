import {
  SeriesLatestYearData,
  SeriesYears,
  VapiSeries,
} from 'models/VAPI/Vapi';

const sortingSeriesByYear = (years: SeriesYears[]) => {
  return years.sort((a: SeriesYears, b: SeriesYears) => b.year - a.year);
};

// gets most recent year for each series
export const getSeriesYear = (data: VapiSeries): SeriesLatestYearData => {
  const seriesLatestYear: SeriesLatestYearData = {} as SeriesLatestYearData;

  for (const series of data.series_list) {
    const newestYear = sortingSeriesByYear(series.years)[0];
    seriesLatestYear[series.seriesId] = {
      code: series.seriesId,
      title: series.seriesName,
      seating: newestYear.seating,
      year: newestYear.year,
      trims: [],
      price: newestYear.price,
    };
  }

  return seriesLatestYear;
};
