import i18n from 'i18next';
import { YearSeries } from '../../../models/VIEW/EstimatorStore';
import { VapiSeriesData } from 'models/VAPI/Vapi';

const sortYearSeriesForDropdown = (yearSeries: YearSeries[]) => {
  return yearSeries.sort((a, b) => {
    // if series is same, but year is incorrect order, sort it
    if (a.series.code === b.series.code && a.year.code < b.year.code) {
      return -1;
    }
    if (a.series.code === b.series.code && a.year.code > b.year.code) {
      return 1;
    }

    if (a.series.code < b.series.code) {
      return -1;
    }
    if (a.series.code > b.series.code) {
      return 1;
    }
    return 0;
  });
};

const createYearSeriesForDropdown = (series: VapiSeriesData[]) => {
  const useLang: string = i18n.language;
  const yearSeries = series.map(seriesPerYear => {
    return seriesPerYear.series_list.map(seriesForYear => ({
      series: { code: seriesForYear.code, title: seriesForYear.title },
      year: { code: seriesPerYear.year, title: seriesPerYear.year },
      models: seriesForYear.trims.map(model => ({
        code: model.code,
      })),
      isLoaded: false,
      title:
        useLang === 'es'
          ? `${seriesForYear.title} ${seriesPerYear.year}`
          : `${seriesPerYear.year} ${seriesForYear.title}`,
    }));
  });
  return yearSeries.flat();
};

export const createYearSeries = (series: VapiSeriesData[]) => {
  const yearSeries = createYearSeriesForDropdown(series);
  return sortYearSeriesForDropdown(yearSeries);
};

export const createSeriesOptions = (series: YearSeries[]) => {
  return series.map((_series, index) => ({
    label: _series.title,
    value: index,
  }));
};
