import BrandService from '../../services/BrandService';
import { displayCategories } from '../../constants/VehicleConstants';

import { Category } from '../../models/VIEW/ZipCodeStore';
import { getTrims, TrimData } from '../../services/TrimsService';
import { ColorData, getJellies } from '../../services/ColorsService';
import { calcLowestMSRPForEachSeriesAndGetJellies } from '../../utils/vehicle';
import { getImageForSeries } from '../../utils/colors';
import { getSeries } from '../../services/SeriesService';
import { getSeriesYear } from '../../utils/series';
import { SeriesLatestYearData } from 'models/VAPI/Vapi';
import { getVehiclesData } from 'services/VehicleDataService';

// calls series, vehicle and colors endpoints
export const createCategoriesForVehicleSelectPage = async (region: string) => {
  const filteredSeries = await getSeries(region);

  //Get DPH for every single vehicle (for latest year)
  await getVehiclesData(filteredSeries);

  // get latest year for each series...
  const seriesForYear = getSeriesYear(filteredSeries);

  if (!seriesForYear) {
    throw new Error('Unable to find series for current year');
  }

  return processSeriesDataForVehicleSelectPage(seriesForYear, region);
};

// grabs vehicle data to compute augmentedMsrp
// and color data to display image...
export const processSeriesDataForVehicleSelectPage = async (
  _series: SeriesLatestYearData,
  region: string
) => {
  const categories: Category[] = initCategories(BrandService.getBrand());
  // populate categories
  const populatedCategories = populateCategories(categories, _series);

  const years = createListOfSeriesAndYears(populatedCategories);
  const vehicleDataResult = await getTrims({ region });

  // update with new augmented msrp taking into account destination fee
  // and car jelly from
  return updateCategoriesWithMSRPAndCarJellies({
    _series,
    year: years,
    vehicleDataResult,
    populatedCategories,
  });
};

const initCategories = (brand: string) => {
  const categories: Category[] = [];
  displayCategories[brand].forEach(category => {
    categories.push({
      code: category.code,
      order: category.order,
      series: [],
    });
  });
  return categories;
};

// populate categories with seriesForView
export const populateCategories = (
  categories: Category[],
  results: SeriesLatestYearData
): Category[] => {
  for (const seriesCode of Object.keys(results)) {
    const series = results[seriesCode];
    const item = {
      year: series.year.toString(),
      series: series.code,
      seriesName: series.title,
      baseMsrp: Number(series?.price?.starting_msrp),
      carJellyImage: '', // need to get this from colors api call
      augmentedMsrp: Number(series?.price?.starting_msrp), // recomputed later on
    };

    categories.forEach(category => {
      if (category.order?.includes(series.code)) {
        const index = category.order.indexOf(series.code);
        const newItem = { ...item, category: category.code };
        category.series[index] = newItem;
      }
    });
  }

  // cleaning up series array if a certain series is not returned by EFC
  categories.forEach(category => {
    category.series = category.series.filter(ser => !!ser);
  });

  return categories;
};

export const createListOfSeriesAndYears = (categories: Category[]) => {
  const years: string[] = []; // years for which we need to grab...
  for (const category of categories) {
    for (const series of category.series) {
      if (!years.includes(series.year)) {
        years.push(series.year);
      }
    }
  }
  return years.join(',');
};

export const updateCategoriesWithMSRPAndCarJellies = async ({
  populatedCategories,
  vehicleDataResult,
  year,
  _series,
}: {
  year: string;
  vehicleDataResult: TrimData;
  populatedCategories: Category[];
  _series: SeriesLatestYearData;
}) => {
  // convert year list array in result to 2d hash map
  const { lowestMsrpByYearSeries, jelliesRequest } =
    calcLowestMSRPForEachSeriesAndGetJellies(vehicleDataResult, _series);

  const jelliesDataResult = await getJellies(jelliesRequest);

  return populatedCategories.map(category => {
    const newSeries = category.series.map(series => {
      //get image for series
      const carJellyImage = getImageForSeries({
        year,
        series: series.series,
        jelliesDataResult: jelliesDataResult as ColorData,
      });

      return Object.assign({}, series, {
        augmentedMsrp: lowestMsrpByYearSeries[series.year]?.[series.series],
        carJellyImage,
      });
    });
    return Object.assign({}, category, { series: newSeries });
  });
};
