import { VCRJelliesRequest } from 'services/WebServicesAPI';
import { TrimData } from '../services/TrimsService';
import { SeriesLatestYearData } from 'models/VAPI/Vapi';

export const getMSRPPriceFromTrim = (
  starting_msrp: string | undefined,
  dph: string | undefined
) => {
  return Number(starting_msrp) + Number(dph);
};
// given trimData, compute lowest price for a given seriesYear
export const calcLowestMSRPForEachSeriesAndGetJellies = (
  vehicleResult: TrimData,
  _series: SeriesLatestYearData
) => {
  const jelliesRequest: VCRJelliesRequest = { vehicles: [] };

  // year => series => augmentedMSRP
  const lowestMsrpByYearSeries: {
    [year: string]: { [series: string]: number };
  } = {};
  for (const year of Object.keys(vehicleResult)) {
    lowestMsrpByYearSeries[year] = {};
    for (const series of Object.keys(vehicleResult[year])) {
      const trims = Object.values(vehicleResult[year][series]);

      // get cheapest price for series across all trims
      const sortedByPrice = trims
        .map(() =>
          getMSRPPriceFromTrim(
            _series[series]?.price?.starting_msrp,
            _series[series]?.price?.dph
          )
        )
        .sort((a, b) => a - b);

      lowestMsrpByYearSeries[year][series] = sortedByPrice[0];

      jelliesRequest.vehicles.push({
        year,
        series,
        trimIds: trims.map(trim => trim.code),
      });
    }
  }

  return { lowestMsrpByYearSeries, jelliesRequest };
};

export const sortModelsByMSRP = <T extends { price: { base_msrp: string } }>(
  models: T[]
) =>
  [...models].sort((a, b) =>
    Number(a.price.base_msrp) < Number(b.price.base_msrp)
      ? -1
      : Number(a.price.base_msrp) > Number(b.price.base_msrp)
      ? 1
      : 0
  );
