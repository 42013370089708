import { EFCModelMap } from '../constants/VehicleConstants';
import { addSizeParam, ColorData } from '../services/ColorsService';

export const getImageForSeries = ({
  year,
  series,
  jelliesDataResult,
}: {
  year: string;
  series: string;
  jelliesDataResult: ColorData;
}) => {
  const vehicleColorToMatch = EFCModelMap[series]?.vehicleColor;
  // return first trim with that a matching color code with EFCModelMap
  const years: string[] = year.split(',').reverse();
  for (const yearSplit of years) {
    for (const trim of Object.keys(
      jelliesDataResult?.[yearSplit]?.[series] ?? {}
    )) {
      if (
        vehicleColorToMatch &&
        vehicleColorToMatch in jelliesDataResult[yearSplit][series][trim]
      ) {
        return `${addSizeParam(
          jelliesDataResult[yearSplit][series][trim][vehicleColorToMatch]
            .car_jelly_image
        )}`;
      }
    }
  }

  // If here, that means we haven't found a matching color code. Pick a random one.
  for (const year of years) {
    if (jelliesDataResult?.[year]?.[series]) {
      const firstTrim = Object.values(jelliesDataResult[year][series] ?? {})[0];
      if (!firstTrim) {
        return '';
      }
      return `${addSizeParam(Object.values(firstTrim)[0]?.car_jelly_image)}`;
    }
  }
  return '';
};
