import { memoizeLast } from 'utils/memo';
import { getVehicleData } from './WebServicesAPI';
import { PriceDetails, VapiSeries } from 'models/VAPI/Vapi';

// e. g. corolla-2025,prius-2024,sienna-2025
const getVehicleDataRequest = (seriesForYear: VapiSeries) => {
  const allVehicleDetails: string[] = [];

  seriesForYear.series_list.forEach(series => {
    series.years.forEach(year => {
      allVehicleDetails.push(`${series.seriesId}-${year.year}`);
    });
  });
  return allVehicleDetails.join();
};

const getVehicleDataInner = async (seriesForYear: VapiSeries) => {
  const vehicleDataRequest = getVehicleDataRequest(seriesForYear);

  try {
    const vehicleData: { data: PriceDetails[] } = await getVehicleData(
      vehicleDataRequest
    );

    seriesForYear.series_list.forEach(series => {
      series.years.forEach(year => {
        const priceDetails = vehicleData.data.find(
          vehicleData =>
            vehicleData.seriesId === series.seriesId &&
            vehicleData.year === year.year
        );

        year.price = {
          dph: priceDetails?.dph,
          starting_msrp: priceDetails?.startingMsrp,
        };
      });
    });
  } catch (e) {
    console.log(e);
  }
};

export const getVehiclesData = memoizeLast(getVehicleDataInner);
