import { memoizeLast } from 'utils/memo';
import { getVCRJellies, VCRJelliesRequest } from './WebServicesAPI';

export type ColorObject = {
  code: string;
  title: string;
  car_jelly_image: string;
};

export type ColorData = {
  [year: string]: {
    [series: string]: {
      [trim: string]: {
        [exteriorCode: string]: ColorObject;
      };
    };
  };
};

const getJelliesInner = async (jelliesRequest: VCRJelliesRequest) => {
  try {
    const result = await getVCRJellies(jelliesRequest);
    return result.data as ColorData;
  } catch (e) {
    console.log(`getColorsErrors: ${e}`);
  }

  return;
};

export const getJellies = memoizeLast(getJelliesInner);

export const addSizeParam = (url: string) => {
  return url ? `${url}?size=450,450` : url;
};
