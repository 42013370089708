import {
  Trim,
  TrimForView,
  VapiSeries,
  VapiSeriesData,
} from 'models/VAPI/Vapi';
import { getVehiclesData } from 'services/VehicleDataService';
import EstimatorStore from '..';
import {
  EFCModelMap,
  ModelMap,
  VehicleCatMap,
} from '../../../constants/VehicleConstants';
import { MYCOModels } from '../../../models/MYCOModels';
import { Grades, GradeVehicleModel } from '../../../models/VIEW/EstimatorStore';
import BrandService from '../../../services/BrandService';
import {
  addSizeParam,
  ColorData,
  getJellies,
} from '../../../services/ColorsService';
import { getSeries } from '../../../services/SeriesService';
import { getTrims, TrimData } from '../../../services/TrimsService';
import {
  getMycoFile,
  VCRJelliesRequest,
} from '../../../services/WebServicesAPI';
import { getQueryParams } from '../../../utils/history';
import { getMSRPPriceFromTrim, sortModelsByMSRP } from '../../../utils/vehicle';
import {
  defaultBuyOffer,
  defaultLeaseOffer,
} from '../Utils/offersFactoryUtils';

export const getGradeGivenCode = ({
  grades,
  gradeCode,
}: {
  grades: Grades;
  gradeCode: string;
}) => grades.find(grade => grade.code === gradeCode);

const mergeTrimsIntoSeries = (
  filteredSeries: VapiSeries,
  allTrims: TrimData
) => {
  const modifiedSeries: VapiSeriesData[] = [];

  filteredSeries.series_list.forEach(series => {
    series.years.forEach(seriesYear => {
      const yearSeries = modifiedSeries.find(
        ser => seriesYear.year.toString() === ser.year
      );

      const trims = Object.values(
        allTrims[seriesYear.year.toString()][series.seriesId]
      ).map(trim => ({ code: trim.code, title: trim.title }));

      const seriesObject = {
        code: series.seriesId,
        title: series.seriesName,
        trims,
      };

      if (!yearSeries?.year) {
        modifiedSeries.push({
          year: seriesYear.year.toString(),
          series_list: [seriesObject],
        });
      } else {
        yearSeries.series_list.push(seriesObject);
      }
    });
  });

  return modifiedSeries;
};

export const getSeriesAndGrades = async ({
  region,
  trim,
}: {
  region: string;
  trim?: string;
}) => {
  const filteredSeries: VapiSeries = await getSeries(region);
  await getVehiclesData(filteredSeries);

  let { grades, allTrims } = await getAndProcessVehicleData(
    region,
    filteredSeries
  );

  grades = filterGradesIfTrimLocked(grades, trim);

  return {
    filteredSeries: mergeTrimsIntoSeries(filteredSeries, allTrims),
    grades,
  };
};

// BAT-1486 filter out grades not relevant to this locked down trim
const filterGradesIfTrimLocked = (grades: Grades, trim?: string) => {
  if (EstimatorStore.lockVehicleTrim) {
    grades = grades.filter(
      item => item.models.filter(model => model.code === trim).length
    );
  }
  return grades;
};

export const getAndProcessVehicleData = async (
  region: string,
  availableSeries: VapiSeries
) => {
  // make getVehicleData call given params in query string...
  const params = getQueryParams();
  const { year, series, msrp, trim } = params;
  if (!series || !year) {
    throw new Error('Missing series or year query string parameter');
  }
  const { agumentedModels, allTrims } = await callAndProcessVehicleEndpoint({
    region,
    year: year as string,
    series: series as string,
    availableSeries,
  });

  // create grades structure
  return {
    grades: createGrades({
      brand: BrandService.getBrand(),
      series: series as string,
      models: agumentedModels,
      trimParam: trim as string,
      msrp: msrp as string,
    }),
    allTrims,
  };
};

const setGradeDetails = (model: TrimForView) => {
  const grade = model.grade;
  return [grade.toLowerCase(), grade] as [string, string];
};

const createGrades = ({
  msrp,
  brand,
  series,
  models,
  trimParam,
}: {
  msrp?: string;
  trimParam?: string;
  brand: string;
  series: string;
  models: TrimForView[];
}) => {
  const grades: Grades = [];
  // sort models by msrp
  const sortedModels = sortModelsByMSRP<TrimForView>(models);
  sortedModels.forEach(model => {
    const newModel = newModelFactory(brand, series, model);

    if (msrp && trimParam && trimParam === model.code) {
      newModel.efcMsrp = newModel.baseMsrp;
      newModel.customMsrp = msrp;
      newModel.baseMsrp = Number(msrp);
      newModel.isCustomMsrp = true;
    }

    const [gradeCode, gradeTitle] = setGradeDetails(model);

    // Get gradeIndex of current grade code
    let gradeIndex = -1;
    for (let i = 0; i < grades.length; i++) {
      if (grades[i].code === gradeCode) {
        gradeIndex = i;
        break;
      }
    }

    // If gradeIndex isn't found, create new grade item in the yearSeries.Obj.grades
    if (gradeIndex === -1) {
      const grade = {
        code: gradeCode,
        title: gradeTitle,
        models: [],
        lastSelectedModel: undefined,
      };
      gradeIndex = grades.push(grade) - 1;
    }

    // Only add model if the model code doesn't already exists in the grades.models array
    if (
      !grades[gradeIndex].models.filter(_model => _model.code === newModel.code)
        .length
    ) {
      grades[gradeIndex].models.push(newModel);
    }
  });
  return grades;
};

const newModelFactory = (
  brand: string,
  series: string,
  model: TrimForView
): GradeVehicleModel => {
  return {
    baseMsrp: getMSRPPriceFromTrim(model.price.base_msrp, model.price.dph),
    category: VehicleCatMap[brand === 'TOY' ? 'toyota' : 'lexus'][series], // this category is unused, and series can now belong to multiple cats
    code: model.code,
    engine: model.shortTitle,
    transmission: model.transmission ?? '',
    trimmedTitle: model.title ? model.title.replace(/\[[^[\]]*]/g, '') : '',
    title: model.title,
    peOffers: {
      buy: defaultBuyOffer(),
      lease: defaultLeaseOffer(),
    },
    carJellyImage: model.carJellyImage,
  };
};

// updates baseMSRP, carJellyImage and carJellyFromBat on seriesForView
export const processGetVehicleDataResponse = async ({
  year,
  series,
  vehicleData,
  jelliesRequest,
}: {
  year: string;
  series: string;
  vehicleData: TrimData;
  jelliesRequest: VCRJelliesRequest;
}): Promise<TrimForView[]> => {
  // we should only be making call for single year/series for EstimatorPage
  const seriesCode = series;
  const seriesItem = EFCModelMap[seriesCode];

  // call colours api, and make sure we create a map of all
  const jelliesResults: ColorData = await getJellies(jelliesRequest);

  const trims = Object.values(vehicleData[year][series]);
  return updateModelsWithShortTitlesAndJellies({
    year,
    series,
    seriesItem,
    models: trims,
    jelliesResults,
  });
};

const updateModelsWithShortTitlesAndJellies = ({
  year,
  series,
  models,
  seriesItem,
  jelliesResults,
}: {
  year: string;
  series: string;
  models: Trim[];
  seriesItem: ModelMap;
  jelliesResults: ColorData;
}) => {
  return models.map((trim: Trim) => {
    // build short title string
    let shortTitle = '';
    if (trim.engine) {
      shortTitle = createShortTitleFromEngine(trim.engine);
    }

    let carJellyImage = '';
    const colorDataForTrim = jelliesResults?.[year]?.[series]?.[trim?.code];
    if (
      colorDataForTrim &&
      seriesItem.vehicleColor &&
      colorDataForTrim[seriesItem.vehicleColor]
    ) {
      carJellyImage += addSizeParam(
        colorDataForTrim[seriesItem.vehicleColor].car_jelly_image
      );
    } else {
      // default back to first exterior color in map for trim
      if (Object.values(colorDataForTrim ?? {})[0]) {
        carJellyImage += addSizeParam(
          Object.values(colorDataForTrim)[0].car_jelly_image
        );
      } else {
        carJellyImage = '/images/blank_car_PE.png';
      }
    }

    return Object.assign({}, trim, {
      carJellyImage,
      shortTitle,
    }) as TrimForView;
  });
};

const createShortTitleFromEngine = (engineTitle: string) => {
  const splitEngine = engineTitle.split(' ');
  return splitEngine.length > 1
    ? `${splitEngine[0]} ${splitEngine[1]}`
    : splitEngine[0];
};

export type CallAndProcessVehicleEndpoint = {
  region: string;
  year: string;
  series: string; // e.g 'highlander'
  availableSeries: VapiSeries;
};
export const callAndProcessVehicleEndpoint = async ({
  region,
  year,
  series,
  availableSeries,
}: CallAndProcessVehicleEndpoint) => {
  const response: TrimData = await getTrims({
    region,
  });

  const mycoModels: MYCOModels = await getMycoFile();

  const validYearModels: Set<string> = new Set<string>();
  mycoModels.yearSeriesModelsNameList.forEach(yearSeries => {
    yearSeries.models.forEach(model => {
      validYearModels.add(`${yearSeries.year}${model.id}`);
    });
  });

  const jelliesRequest: VCRJelliesRequest = { vehicles: [] };

  Object.keys(response).forEach(responseYear => {
    Object.keys(response[responseYear]).forEach(responseSeries => {
      const trims = Object.keys(response[responseYear][responseSeries]);

      jelliesRequest.vehicles.push({
        year: responseYear,
        series: responseSeries,
        trimIds: trims,
      });

      trims.forEach(trim => {
        response[responseYear][responseSeries][trim].price.dph = getDphForTrim(
          availableSeries,
          responseSeries,
          responseYear
        );

        if (!validYearModels.has(`${responseYear}${trim}`)) {
          delete response[responseYear][responseSeries][trim];
        }
      });
    });
  });

  return {
    agumentedModels: await processGetVehicleDataResponse({
      year,
      series,
      vehicleData: response,
      jelliesRequest,
    }),
    allTrims: response,
  };
};

export const getDphForTrim = (
  availableSeries: VapiSeries,
  seriesId: string,
  year: string
) => {
  const selectedSeries = availableSeries.series_list.find(
    series => series.seriesId === seriesId
  );

  return selectedSeries?.years.find(
    selectedYear => selectedYear.year.toString() === year
  )?.price.dph;
};
